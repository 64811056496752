import React from 'react';

const Cart: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4C3 3.44772 3.44772 3 4 3H22C22.3161 3 22.6136 3.14946 22.8023 3.40307C22.991 3.65668 23.0487 3.98457 22.9578 4.28735L19.9578 14.2873C19.8309 14.7103 19.4416 15 19 15H4C3.44772 15 3 14.5523 3 14C3 13.4477 3.44772 13 4 13H18.256L20.656 5H4C3.44772 5 3 4.55228 3 4Z"
      />
      <path d="M4 24C5.10457 24 6 23.1046 6 22C6 20.8954 5.10457 20 4 20C2.89543 20 2 20.8954 2 22C2 23.1046 2.89543 24 4 24Z" />
      <path d="M20 24C21.1046 24 22 23.1046 22 22C22 20.8954 21.1046 20 20 20C18.8954 20 18 20.8954 18 22C18 23.1046 18.8954 24 20 24Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L4.70711 3.29289C4.89464 3.48043 5 3.73478 5 4V17H23C23.5523 17 24 17.4477 24 18C24 18.5523 23.5523 19 23 19H4C3.44772 19 3 18.5523 3 18V4.41421L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
      />
    </svg>
  );
};

export default Cart;
